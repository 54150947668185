import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormAction } from '../../../../contexts/FormActionContext';
import { Option } from '../../../Option';
import ActionPreview from '../../ActionPreview';
import { ActionPreviewBaseProps } from '../ActionBaseProps';

const YesNoPreview: FC<ActionPreviewBaseProps<Option<'yes' | 'no' | 'na', string>>> = (props) => {
  const { answerOnly, response, data } = props;
  const { question, previewQuestion, previewDescription } = data;
  const { riskLevel } = useFormAction();

  const { t } = useTranslation('activity-type');

  const title = useMemo(() => previewQuestion || question, [previewQuestion, question]);

  const answer = useMemo(() => {
    if (!response) {
      return '-';
    }

    if (response.id === 'na') {
      return t(`yes-no.not-applicable`);
    }

    return t(`yes-no.${response.id}`);
  }, [response, t]);

  if (answerOnly) {
    return <>{answer}</>;
  }

  return <ActionPreview data-cy="yes-no-preview" riskLevel={riskLevel} question={title} description={previewDescription} answer={answer} />;
};

export default YesNoPreview;
